.home {
    text-align: center;
    margin-top: 3rem;
    padding: 2rem;
  }
  
  .home h1 {
    font-size: 2.5rem;
    color: #ECDFCC;
  }
  
  .home p {
    font-size: 1.2rem;
    font-family: "Times New Roman", Times, serif;
    color: #ECDFCC;
    max-width: 600px;
    margin: 1.5rem auto;
    line-height: 1.6;
  }

/* Style for the form container */
.home form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Style for the textarea */
.home textarea {
  width: 100%;
  max-width: 500px;
  height: 25px; /* Start with a smaller height */
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  resize: none; /* Disable manual resizing */
  overflow-y: hidden; /* Hide scrollbar until it’s necessary */
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

/* Hover and focus effect */
.home textarea:focus {
  border-color: #007bff;
  box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
}