/* Container to slightly shift the page to the left */
.cv-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: left;
    transform: translateX(-20px);
  }

  .cv-container h1 {
    color: #ECDFCC
  }
  .cv-container p {
    color: #ECDFCC
  }
  .cv-container h2 {
    color: #ECDFCC
  }
  /* Style for the download button */
  button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Style each experience item with a shaded background */
  .experience-item , .education-item{
    background-color: #f9f9f9; /* Light grey background */
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin: 15px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .experience-item h2 .education-item h2{
    margin: 0;
    font-size: 1.3rem;
    color: #333;
  }
  
  .experience-item h3 .education-item h3{
    margin: 5px 0;
    font-size: 1.1rem;
    color: #666;
  }
  
  .experience-item p .education-item p{
    margin: 5px 0;
    color: #555;
  }